import { createRouter, createWebHistory } from 'vue-router'
import Start from '../views/Start.vue'
import Home from '../views/Home.vue'
import Scanner from '../views/Scanner.vue'



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },


 {    path: '/scanner',
 name: 'Scanner',
 component: Scanner
},

  {    path: '/start',
   name: 'Start',
   component: Start
 }
               
        
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
