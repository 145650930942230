<template>
  <div id="app" class="rgba-gradient" v-on:contextmenu.prevent="onRightClick">
    <div>
      <Dialog
        header="Tip"
        position="top"
        :closeOnEscape="true"
        :dismissableMask="true"
        :modal="true"
        :closable="true"
        v-model:visible="displayWarning"
        :style="{ width: '80vw', margin: '50px' }"
      >
        <div class="flex flex-col justify-center">
          <div
            class="flex text-center text-gray-600 text-2xl justify-center font-bold"
          >
            Bitte nur kurz eintippen!
          </div>
          <div class="flex">
            <img
              src="https://res.cloudinary.com/dde9xhzio/image/upload/v1598197795/POS/galaxy_s20_touchscreen_gestures_single_tap.gif"
              class="mx-auto"
            />
          </div>
          <div clas="flex">
            <div
              @click="closeNow"
              class="text-2xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-6 text-center"
              style="background-color: #9c27b0"
            >
              OK
            </div>
          </div>
        </div>
      </Dialog>
    </div>
    <router-view class="routview" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      displayWarning: false,
    };
  },

  methods: {
    onRightClick() {
      this.displayWarning = true;
      console.log("right clicked");
    },

    closeNow() {

     this.displayWarning = false;
      
    }

  },

  mounted() {},
};
</script>

<style>
#app {
  font-family: "Exo 2", sans-serif;
  -webkit-touch-callout: none; /* Disables long-touch menu */
  -webkit-user-select: none; /* Disable text selection (for Webkit) */
  user-select: none; /* Disable text selection (standard syntax) */
 
}


.rgba-gradient {
  overflow: hidden;
  background: -webkit-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: -o-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: -moz-linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
}
</style>
