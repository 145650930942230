import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import {PrimeIcons} from 'primevue/api';
import Chip from 'primevue/chip';
import BlockUI from 'primevue/blockui';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import Sidebar from 'primevue/sidebar';
import OverlayPanel from 'primevue/overlaypanel';
import Carousel from 'primevue/carousel';
import Vue3TouchEvents from "vue3-touch-events";
import QrReader from 'vue3-qr-reader';
import VueLottiePlayer from "vue-lottie-player"



import OhVueIcon from "oh-vue-icons/components/icon-v3";

import { FcCableRelease, BiPlusSquare , BiCreditCard2Back , CoHome, RiQrScanLine, FaQrcode, BiTrash ,PrSlack, BiSearch, BiCartPlus, BiCartCheck , RiRefreshLine, CoQrCode, OiCheckCircle,FaInfoCircle,HiShoppingCart,IoWalletOutline,BiCheckCircleFill,CoPrint,RiRestartFill,
  FaShoppingBasket, FaMoneyBill, IoCardOutline, HiArrowCircleDown, OiInfo, RiDeleteBinLine, FaEquals, RiHomeSmileLine, BiUpcScan,PxWallet,BiXCircleFill,LaHandPointer, 
FaRegularQuestionCircle, FaBackward, LaShoppingBasketSolid,RiErrorWarningLine , BiXCircle, BiArrowDownCircle,BiFlower1,FaLeaf,RiSeedlingLine, HiBackspace,
  FaArrowRight, RiHome8Line, RiRestartLine, OiSquare, LaMoneyBillWaveSolid, GiReceiveMoney, FaBan, BiChevronDoubleRight, HiSolidArrowCircleDown, HiSolidArrowCircleRight,
FaArrowAltCircleDown,HiWifi,RiLockPasswordLine,LaWifiSolid,FaBitcoin,RiNumber1,FaArrowAltCircleRight,BiShieldCheck,BiXSquareFill,BiCheckSquareFill,CoCreditCard,CoScreenSmartphone            } from "oh-vue-icons/icons";
OhVueIcon.add([FcCableRelease, BiPlusSquare , BiCreditCard2Back, CoHome, RiQrScanLine, BiTrash , FaQrcode, PrSlack, BiSearch, BiCartPlus, BiCartCheck , RiRefreshLine, CoQrCode, OiCheckCircle,FaInfoCircle,HiShoppingCart,IoWalletOutline,BiCheckCircleFill,CoPrint,RiRestartFill,
  FaShoppingBasket, FaMoneyBill, IoCardOutline, HiArrowCircleDown, OiInfo, RiDeleteBinLine,FaEquals, RiHomeSmileLine, BiUpcScan,PxWallet,BiXCircleFill,LaHandPointer, 
FaRegularQuestionCircle, FaBackward, LaShoppingBasketSolid,RiErrorWarningLine, BiXCircle , BiArrowDownCircle,BiFlower1,FaLeaf, RiSeedlingLine,HiBackspace,
  FaArrowRight, RiHome8Line, RiRestartLine, OiSquare, LaMoneyBillWaveSolid, GiReceiveMoney, FaBan, BiChevronDoubleRight, HiSolidArrowCircleDown, HiSolidArrowCircleRight,
FaArrowAltCircleDown,HiWifi,RiLockPasswordLine,LaWifiSolid,FaBitcoin,RiNumber1,FaArrowAltCircleRight,BiShieldCheck,BiXSquareFill,BiCheckSquareFill,CoCreditCard,CoScreenSmartphone            ]);

import './index.css'
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-green/theme.css';
import 'primevue/resources/primevue.css';
import 'primeicons/primeicons.css';


const app = createApp(App);


// Development Config

// app.config.globalProperties.serverurl = 'https://posadmin2021.ddns.net:1979'
// app.config.globalProperties.ebillserver = "https://www.freudental.ch/one/invoicedev/"
// app.config.globalProperties.callbackurl = 'https://www.coverpage.ch/dev/closing/'
// app.config.globalProperties.devenv = 'ENVTES'
// app.config.globalProperties.devver = '2.2.0'
// app.config.globalProperties.ShopPos = false,

// Production Config

app.config.globalProperties.serverurl = 'https://posadmin2021.ddns.net:1818'
app.config.globalProperties.ebillserver = "https://www.freudental.ch/one/invoice/"
app.config.globalProperties.callbackurl = 'https://www.coverpage.ch/pos/closing/'
app.config.globalProperties.devenv = 'ENVPRO'
app.config.globalProperties.devver = '2.3.1'
app.config.globalProperties.ShopPos = true,


app.component("v-icon", OhVueIcon);

app.component('Dialog', Dialog);
app.component('InputText', InputText);
app.component('Tag', Tag);
app.component('Chip', Chip);
app.component('BlockUI', BlockUI);
app.component('ConfirmPopup', ConfirmPopup);
app.component('Sidebar', Sidebar);
app.component('OverlayPanel', OverlayPanel);
app.component('Carousel', Carousel);


app.use(store);
app.use(router);
app.use(PrimeVue);
app.use(QrReader);
app.use(VueLottiePlayer);
app.use(Vue3TouchEvents);

app.use(ConfirmationService);
// app.use(QrReader);
// app.use(LottieAnimation);

app.mount("#app");
