<template>
  <div
    class="min-w-full min-h-full flex pt-3 px-3 absolute overflow-hidden mt-0 rgba-gradient"
    @keyup.enter="keymonitor"
  >
    <div class="w-full text-gray-800 bg-transparent">
      <Navbar step="Start" screenguide="welcome"></Navbar>
      <div class="min-w-screen flex items-center">
        <div class="flex bg-white shadow-xl mx-auto text-gray-800 py-8">
          <div class="flex items-start">
            <div
              class="w-2/5 px-20 border-r-2 border-gray-300 border-dashed my-5"
            >
              <div class="flex justify-center mb-3">
                <img
                  src="https://res.cloudinary.com/dde9xhzio/image/upload/v1617612945/POS/web_logo_l.jpg"
                  class="h-16"
                />
              </div>
              <div class="flex w-full h-96">
                <lottie-animation path="pos/1234-intro.json" />
              </div>
            </div>
            <div class="w-3/5 px-16">
              <div
                class="flex text-left font-bold uppercase text-3xl mb-10 text-green-500 mt-5"
              >
                Willkommen im Freudental Hofladen
                <button
                  type="button"
                  id="submitBtn"
                  stlye="visibility:show"
                  @contextmenu.prevent
                />
              </div>

              <div class="text-2xl my-5 font-bold text-gray-500 text-left">
                Sie können Produkte selbst einscannen und in Bar, mit der Karte
                oder Smartphone, mit Twint oder mit Bitcoin bezahlen.
              </div>
              <div class="flex items-center my-5 mt-10">
                <img
                  @click="playSound"
                  src="https://res.cloudinary.com/dde9xhzio/image/upload/v1584902932/POS/shoplogo13.png"
                />
              </div>
              <div
                class="text-2xl text-gray-400 font-bold flex justify-center mt-10" v-if="!showWarning"
              >
                <v-icon name="bi-arrow-down-circle" scale="1.5" class="mx-3" />
                Bitte tippen Sie auf die Taste "Scannen" {{scanresult}}
                <v-icon name="bi-arrow-down-circle" scale="1.5" class="mx-3" />
              </div>

              <div>
                <!-- Start Pay button -->

                <div class="flex justify-center align-bottom mt-8" v-if="!showWarning">
                  <!-- <router-link
                    :to="{
                      name: 'Search',
                      params: { sid: 'new' },
                    }"
                  > -->

                  <button @click="didUserGrantPermission"> Scan </button>
                    <button
                      v-touch:tap="touchHandler"
             v-touch:longtap="touchHandler"
             @click="newspeak"
                      class="text-xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-36 ml-2 flex items-center"
                      style="background-color: #9c27b0"
                    >
                          <v-icon
                      name="la-hand-pointer"
                    scale="1.5"
                    animation="pulse"
                    class="mr-2"
                  v-if="showwaiticon" /> Scannen
                      <svg
                        class="h-5 w-5 ml-2 fill-current"
                        clasversion="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="-49 141 512 512"
                        style="enable-background: new -49 141 512 512"
                        xml:space="preserve"
                      >
                        <path
                          id="XMLID_11_"
                          d="M-24,422h401.645l-72.822,72.822c-9.763,9.763-9.763,25.592,0,35.355c9.763,9.764,25.593,9.762,35.355,0
                                     l115.5-115.5C460.366,409.989,463,403.63,463,397s-2.634-12.989-7.322-17.678l-115.5-115.5c-9.763-9.762-25.593-9.763-35.355,0
                                     c-9.763,9.763-9.763,25.592,0,35.355l72.822,72.822H-24c-13.808,0-25,11.193-25,25S-37.808,422-24,422z"
                        />
                      </svg>
                    </button>
                  <!-- </router-link> -->
                </div>
                <!-- End Pay button -->
              </div>
            </div>


     <Dialog
                    position="center"
                    header="System Offline"
                    :closable="true"
                    :modal="true"
                    :dismissableMask="true"
                    v-model:visible="displayModal"
                    :style="{ width: '70vw', margin: '50px' }"
                  >
                <div
                  class="text-2xl text-white font-bold flex justify-center mt-10 bg-red-600 px-4 py-4 rounded-lg"
                >
                 
                  Das Kasse-System ist momentan leider nicht verfügbar, bitte drücken Sie auf die Türklingel rechts, wir helfen Ihnen gerne weiter.
                 
                </div>

     </Dialog>
  

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <Footer></Footer> -->
</template>

<script>
import Navbar from "../components/NavBar.vue";
import Footer from "../components/Footer.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { TextToSpeech } from '@capacitor-community/text-to-speech';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';



export default {
  components: { Navbar, Footer, LottieAnimation },

  data() {
    return {
      selectedproduct: "",
      blockedPanel: true,
      showOverlay: false,
      showWait: false,
      ShowDelete: false,
      showScanmore: false,
      ShowEmpty: true,
      showNotFound: false,
      CartWait: false,
      opacity: 0.3,
      persistent: false,
      persistentNoAnimation: false,
      dismissible2: true,
      product: "",
      cart: "",
      myplaceholder: "Bitte scannen ...",
      placeholdercolor: true,
      sid: "NbzsH0i3l8y3QQbP8IlXulq15iV7r6hI",
      deleteresult: "",
      displayModal: false,
      modalname: "",
      modalid: "",
      serverfeedback: "",
      displayModal: false,
      showWarning: true,
      showwaiticon: false,
      scanresult:"1234"
    };
  },

  methods: {

 async didUserGrantPermission() {
  // check if user already granted permission
  const status = await BarcodeScanner.checkPermission({ force: true });

  if (status.granted) {
    // user granted permission

      BarcodeScanner.hideBackground(); 

  const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

  // if the result has content
  if (result.hasContent) {
    console.log(result.content); // log the raw scanned content
     BarcodeScanner.showBackground(); 
  }

   this.scanresult=result.content;

    return true;
  }

  if (status.denied) {
    // user denied permission
    return false;
  }

  if (status.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (status.neverAsked) {
    // user has not been requested this permission before
    // it is advised to show the user some sort of prompt
    // this way you will not waste your only chance to ask for the permission
    const c = confirm(
      'We need your permission to use your camera to be able to scan barcodes',
    );
    if (!c) {
      return false;
    }
  }

  if (status.restricted || status.unknown) {
    // ios only
    // probably means the permission has been denied
    return false;
  }

  // user has not denied permission
  // but the user also has not yet granted the permission
  // so request it
  const statusRequest = await BarcodeScanner.checkPermission({ force: true });

  if (statusRequest.asked) {
    // system requested the user for permission during this call
    // only possible when force set to true
  }

  if (statusRequest.granted) {
    // the user did grant the permission now

      BarcodeScanner.hideBackground(); 

  const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

  // if the result has content
  if (result.hasContent) {
    console.log(result.content); // log the raw scanned content
    BarcodeScanner.showBackground(); 
  }


    return true;
  }

  // user did not grant the permission, so he must have declined the request
  return false;
},


async startscan() {

  this.didUserGrantPermission(); 

  BarcodeScanner.hideBackground(); 

  const result = await BarcodeScanner.startScan(); // start scanning and wait for a result

  // if the result has content
  if (result.hasContent) {
    console.log(result.content); // log the raw scanned content
    BarcodeScanner.showBackground(); 
  }

}, 


async newspeak() {

   console.log("speak exec")

  await TextToSpeech.speak({
    text: 'Das ist ein Test-Produkt mit Sprachausgabe.',
    lang: 'de-DE',
    rate: 1.0,
    pitch: 1.0,
    volume: 1.0,
    category: 'ambient',
  });

        await TextToSpeech.stop();


await TextToSpeech.getSupportedVoices();


},

    keymonitor(event) {
      console.log("enter key was pressed!");

      if (event.code == "Enter") {
                 this.$router.push({
        name: "Search",
       params: { sid: 'new' }
      });
// 
        // this.speak3();
      }
    },

        getPing() {
      fetch(this.serverurl + "/api/getBackendPing/", {
  timeout: 5000
})
        .then((res) => res.json())
        .then((data) => (this.serverfeedback = data))  
        .then(() => this.checkserver())  
 .catch(err =>  this.checkerror(err) )
    },

    touchHandler() {

  console.log("tapped on the screen")
  // this.speak();
  this.showwaiticon = true;

},

        checkerror(errmsg) { 
        if (errmsg) {
        console.log('Network offline')
        this.displayModal = true
        this.showWarning = true

          }

        },


    checkserver() {
      
      if (this.serverfeedback.result == 'ok') {
        this.showWarning = false,
        this.displayModal = false
      }

      else
      {

        this.displayModal = true
        this.showWarning = true

      }
    

    },



    speak3() {
      window.speechSynthesis.cancel();
      let msg = "Bitte mit dem QR-Code Scanner oder Hand QR-Scanner den Artikel einscannen, das Produkt wird danach automatisch im Warenkorb hinzugefügt.";
      let speech = new SpeechSynthesisUtterance();
      speech.lang = "de-DE";
      speech.text = msg;
      speech.volume = 1;
      speech.rate = 1;
      speech.pitch = 1;
      setTimeout(() => window.speechSynthesis.speak(speech), 1000);
    },

    speak() {
      this.playSound();
      window.speechSynthesis.cancel();
      let msg =
        "Bitte mit dem QR-Code Scanner oder Hand QR-Scanner den Artikel einscannen, das Produkt wird danach automatisch im Warenkorb hinzugefügt.";
      let speech = new SpeechSynthesisUtterance();
      speech.lang = "de-DE";
      speech.text = msg;
      speech.volume = 1;
      speech.rate = 1;
      speech.pitch = 1;
      setTimeout(() => window.speechSynthesis.speak(speech), 1000);
    },

    speak2() {
      window.speechSynthesis.cancel();
      let msg =
        'Willkommen im Freudental, Sie können Produkte selbst einscannen und bezahlen. Zu beginnen, bitte tippen Sie auf die Taste "Starten"';
      let speech = new SpeechSynthesisUtterance();
      speech.lang = "de-DE";
      speech.text = msg;
      speech.volume = 1;
      speech.rate = 1;
      speech.pitch = 1;
      return window.speechSynthesis.speak(speech);
    },

    playSound() {
      var sound =
        "https://res.cloudinary.com/dde9xhzio/video/upload/v1617796719/POS/sound.mp3";
      var audio = new Audio(sound);
      audio.play();
      console.log("sound played");
      window.navigator.vibrate([250, 250]);
    },
  },

  computed: {},

  mounted() {
    document.addEventListener("keyup", this.keymonitor);
        this.getPing();
  },

  unmounted() {
    document.removeEventListener("keyup", this.keymonitor);
  },
};
</script>

<style>
-webkit-touch-callout: none;
-webkit-user-select: none; /* Disable selection/copy in UIWebView */
</style>
