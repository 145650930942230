<template>
  
<div class="min-w-screen min-h-screen flex items-start justify-center px-3 py-3">
  
      <div class="bg-white text-gray-800 rounded-xl shadow-lg flex w-full" >
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl overflow-y-auto">
            <div class="mb-3 mt-5">

              <div>      <img
                  src="https://res.cloudinary.com/dde9xhzio/image/upload/v1617612945/POS/web_logo_l.jpg"
                  class="mx-auto h-16"
                /></div>
                
               

      </div>
            

        <div
                class="overflow-y-auto rounded-2xl mt-5"
                style="height: 30rem"
                ref="messagesContainer"
              >


<div class="w-full">

               <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets2.lottiefiles.com/packages/lf20_fknfveir.json"
        class="max-h-48 mx-auto"
      />    

</div>

 <h1 class="text-2xl font-bold uppercase text-center text-gray-500 my-5">   <v-icon name="fa-qrcode" scale="1.3" class="mr-2 mb-1" /> Mobile Check Out</h1>

 <div class="flex w-full">
   <!-- <div class="flex w-2/5">
   
                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets7.lottiefiles.com/packages/lf20_ZiAFmm.json"
        class="max-h-32 mx-auto"
      />    

   
   </div> -->


     <div class="text-xl font-bold text-center flex w-5/5 my-5 px-2">  Sie können mit diesem Smartphone Produkte einscannen</div>     

            </div>
            <div class="mt-5 flex items-center justify-center">
          <router-link
            :to="{
              name: 'Scanner',
            }"
          >
            <button
           
              class="text-xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-24 flex justify-center text-center"
              style="background-color: #9c27b0"
              @click="playSound"
            >
              <v-icon
                name="la-hand-pointer"
                    scale="1.5"
                    animation="pulse"
                class="mr-2"
                      />Starten
            </button>
          </router-link>
        </div>
     
           
            </div>
                 
            
 
        </div>


    </div>


</div>

 


</template>

<script>

import Footer from "../components/Footer.vue";

export default {

  components: { Footer },

name: "Home",
 

  data() {
    return {
      showwaiticon: false,
    };
  },

  methods: {

        playSound() {
      var sound =
        "https://res.cloudinary.com/dde9xhzio/video/upload/v1618136376/POS/Computer_Magic-Microsift-1901299923.mp3";
      var audio = new Audio(sound);
      audio.play();
      console.log("sound played");
      window.navigator.vibrate([250, 250]);
    },

 
    refreshhomepage() {
      window.location.reload();
    },

    loaded() {

    },



  },

  mounted() {
  

  },

    unmounted() {
  },


};
</script>

<style>


</style>
