<template>
  <div>

    <div class="w-full">
      <qr-stream @decode="onDecode">
        <div style="color: red" class="frame"></div>
      </qr-stream>
    </div>
    <!-- <div class="flex text-sm text-center my-2 justify-center">
      Result: {{ data }}
    </div> -->
    
  </div>

  

</template>
<script>

import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "QrStreamExample",
  emits: ["senddata", "closeme"],
  setup(props, { emit }) {
    const state = reactive({
      data: null,
    });
    function onDecode(data) {
      state.data = data;
      emit("senddata", data);
      console.log("event sent");
    }
    return {
      ...toRefs(state),
      onDecode,
    };
  },
});
</script>

<style scoped>
.frame {
  border-style: solid;
  border-width: 2px;
  border-color: red;
  height: 50vw;
  width: 50vw;
  position: absolute;
  top: 50px;
  bottom: 50px;
  right: 50px;
  left: 50px;
  margin: auto;
}
</style>
