<template>

<div> 
    <Dialog
      header="Info"
      position="top"
      :closeOnEscape="false"
      :dismissableMask="false"
      :modal="false"
      :closable="false"
      v-model:visible="displayReset"
      :style="{ width: '90vw', margin: '50px' }"
    >
      <div class="flex justify-center text-red-500">
        <v-icon name="ri-restart-fill" scale="15" animation="spin" />
      </div>
      <div
        class="flex text-center text-gray-600 text-xl font-bold justify-center m-5"
      >
        Die Kasse wird zurückgesetzt...
      </div>

      <div clas="flex">
        <div
          @click="closeReset"
          class="bg-red-500 text-2xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-6 text-center"
        >
          Abrechen
        </div>
      </div>
    </Dialog>

  
                        <Dialog
          position="top"
            :closeOnEscape="true"
            :dismissableMask="true"
            :modal="true"
            :closable="true"
            v-model:visible="displayModal4"
            :style="{ width: '90vw', margin: '10px' }"
          >

          <div class="text-lg font-bold text-gray-400 mb-2" v-if="productinfo"> {{productinfo.name}} | CHF {{productinfo.price.toFixed(2)}}/kg </div>

            <div
              class="flex justify-center items-center w-full text-gray-600 font-bold text-base" 
            >
              Bitte Gewicht in KG (z.B. 1.20 kg) eingeben und mit der "OK-Taste" bestätigen: 
            </div>


            <div class="flex justify-center items-center w-full">
    
              <div class="flex items-center justify-center px-5 py-7">
                <div
                  class="w-full mx-auto rounded-xl bg-gray-100 shadow-xl text-gray-800 relative overflow-hidden"
                >
                  <div
                    class="w-full h-24 bg-gradient-to-b from-gray-800 to-gray-700 flex items-end text-right px-16 py-2"
                  >
                    <div class="w-full py-5 px-6 text-3xl text-white font-thin">
                      {{ totalnumber }} kg
                    </div>
                  </div>
                  <div
                    class="w-full bg-gradient-to-b from-indigo-400 to-indigo-500"
                  >
                    <div class="flex w-full">
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(7)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          7
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(8)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          8
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(9)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          9
                        </button>
                      </div>
                    </div>
                    <div class="flex w-full">
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(4)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          4
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(5)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          5
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(6)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          6
                        </button>
                      </div>
                    </div>
                    <div class="flex w-full">
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(1)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          1
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(2)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          2
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-b border-indigo-400">
                        <button
                          @click="addnumber(3)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          3
                        </button>
                      </div>
                    </div>
                    <div class="flex w-full">
                      <div class="w-1/3 border-r border-indigo-400">
                        <button
                          @click="addnumber(0)"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          0
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-indigo-400">
                        <button
                          @click="addnumber('.')"
                          class="w-full h-16 outline-none focus:outline-none hover:bg-indigo-700 hover:bg-opacity-20 text-white text-xl font-light"
                        >
                          .
                        </button>
                      </div>
                      <div class="w-1/3 border-r border-indigo-400">
                        <button
                          @click="deletenumber"
                          class="w-full h-16 outline-none focus:outline-none bg-red-700 text-white text-xl font-light"
                        >
                          <v-icon name="hi-backspace" scale="1.5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="checkPricebyKG"
              class="text-white flex text-xl justify-center m-2 py-1 font-bold bg-red-500 rounded-lg"
            >
              {{ checkkgtext }}
            </div>

            <div class="flex justify-end mr-5" v-if="!checkPricebyKG">
      
              <button
                @click="closePricebyWeight"
                class="flex uppercase shadow bg-red-600 text-white text-base m-2 py-3 px-2 rounded"
              >
                <v-icon name="bi-x-circle" scale="1.2" class="mx-2" />Abbrechen
              </button>
                      <button
                @click="getProductbyWeight(productinfo.sku, sid, totalnumber)"
                class="flex uppercase shadow bg-green-600 text-white text-base m-2 py-3 px-8 rounded"
              >
                <v-icon name="oi-check-circle" scale="1.2" class="mx-2" />OK
              </button>
            </div>
          </Dialog>
        

<div v-if="!showcart && !showpay">
  
<div class="min-w-screen min-h-screen flex items-start justify-center px-3 py-3" >
  
      <div class="bg-white text-gray-800 rounded-3xl shadow-lg flex w-full" >
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl overflow-y-auto">
            <div class="mb-3 ">
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-if="showQRscanner">Bitte QR code scannen</div>
         
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-else>Bitte Anzahl auswählen</div>
         
            </div>
            

        <div v-if="showQRscanner"
                class="overflow-hidden my-4 max-h-80"
                         ref="messagesContainer"
                       
              >

      <QrReader @senddata="onDecoded" />

                      
           
            </div>
                 
                         <div class="flex mt-0" v-if="itemfound && !item.message && item">
      
            <div class="w-full bg-white rounded-lg flex flex-row justify-center items-start" >
       
                <div class="mb-1"  @click="getInfo(item.sku, item.name)" >
                    <img class="flex rounded-full max-h-32 max-w-32 mx-auto" :src="item.pic2" alt="photo" > 
                    <div class="text-xs text-gray-500 text-center items-start"> {{item.sku}} </div>
                </div>
                <div class="justify-center" >
                    <div class="text-left text-xl text-gray-700 font-bold mx-4">{{item.name}} </div>
            
                 <div class="flex text-xl text-gray-500 font-bold mx-4 text-left justify-left"> CHF {{item.price.toFixed(2)}} </div>
                    <div class="mt-2 justify-left items-left flex mx-4">
        
                 <button
                    class="
                      bg-blue-700
                      text-white text-xl
                      px-4
                      py-1
                      rounded-sm
                      shadow-md
                      mr-0
                    "
                    @click="removeqty()                
                    "
                  >
                  - </button>

                           
                    <input
                      v-model="selectedorderqty"
                      :min="1"
                      class="bg-gray-200 w-1/5 h-10 text-center text-lg mx-2" 
                    />
                  

                                                <button
                    class="
                      bg-blue-700
                      text-white text-xl
                      px-5
                      py-1
                      rounded-sm
                      shadow-md
                      mr-1
                    "
                    @click="addqty"                  >

                  + </button>



                  </div>
                  
           </div>

                </div>

            </div>                    
                                       <div class="flex flex-row justify-evenly mt-4" v-if="itemfound && !item.message && item">
          
             <div v-if="!showwait2">
                                      <button class="border-2 border-white bg-green-600 text-xl shadow-xl text-white rounded-lg font-bold flex items-center mx-2 px-12 py-3"
                 @click="getProduct(item.sku,sid,selectedorderqty)" v-if="selectedorderqty > 0">                                                
                                       {{selectedorderqty}}x einkaufen
                                    <v-icon name="bi-cart-plus" animation="pulse" scale="1.5" class="ml-2" />
                           
                           </button>
                           </div>
                           
             <div class="w-full bg-white rounded-lg flex flex-row justify-center items-center" v-if="showwait2">

        <div class="text-base text-center flex text-green-600"><v-icon name="bi-shield-check" scale="1.5" class="text-green-600" /> Produkt im Warenkorb hinzugefügt </div>             
                    
            </div>


            </div>

                     
                              <div class="w-full bg-white rounded-lg flex flex-row justify-center items-center" v-if="showait && !showwait2 ">

        <div class="text-lg font-bold text-center flex"> 
          
          <v-icon name="ri-refresh-line" animation="spin" scale="1.5" class="text-gray-500 mx-2" />
          
          
          Produktinformation wird aktualisiert. Sie können weiter scannen.

        </div>             

 
            </div>




                 <div class="w-full bg-white rounded-lg flex flex-row justify-center items-center" v-if="!itemfound && !firstscan && item">

        
                        <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets5.lottiefiles.com/packages/lf20_yw3nyrsv.json"
        class="max-h-36"
      />    

        <div class="text-lg font-bold text-center flex"> Produkt wurde nicht gefunden, bitte nochmals scannen


        </div>             
 
 
            </div>


              <div class="w-full bg-white rounded-lg flex flex-row justify-center items-center" v-if="firstscan && !showwait2">

                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets5.lottiefiles.com/packages/lf20_8hthlleg.json"
        class="max-h-24"
      />

        <div class="text-lg font-bold text-center flex"> Bitte scannen Sie den QR-Code auf der Produktverpackung oder Etiketten </div>             
                    

        
            </div>
  
 
        </div>



    </div>

    </div>

  </div>




  <div class="min-w-screen min-h-screen flex items-start justify-center px-3 py-3" v-if="showcart && !showpay">

    
  
      <div class="bg-white text-gray-800 rounded-xl shadow-lg flex w-full" v-if="cart">
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl  overflow-y-auto">
            <div class="mb-3 ">
                <div class="text-2xl font-bold uppercase text-center"> <v-icon name="la-shopping-basket-solid" scale="1.3" class="mb-1"/> Warenkorb</div> 


                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-if ="cart.items.length >= 1" >{{cart.items_qty}} Artikel | CHF {{totalcart}}</div>
           
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-else >0 Artikel | CHF 0.00</div>
            </div>
            

        <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
                   v-if ="cart.items.length >= 1"
              >
      <div
                  v-for="item in cart.items"
                  :key="item.item_id"
                  class="border-blue-500"
             >

<!-- Start Each Product -->

                <div class="flex w-full">
                    <div class="flex w-1/5 text-gray-600 px-4 py-4  justify-center text-center items-center rounded-full font-bold text-base bg-gray-200" >{{item.qty}} x </div>
                    <div class="pl-3 w-4/5">
                        
                        <div class="text-lg font-semibold leading-tight mb-1 ml-2">{{item.name}} à CHF {{item.price.toFixed(2)}}</div>
                          <div class="flex w-full items-center text-xs text-gray-500 font-medium">
                          <div class="flex mx-2">
                        <button
                          class="flex uppercase shadow bg-green-600 text-white text-sm pt-1 pb-1 mt-1 px-4 rounded"
                          @click="getInfo(item.sku, item.name)" >
                          <v-icon name="oi-info" scale="1" class="mr-1" />Info
                        </button>
                      </div>

                                 <div class="flex mx-2"  v-if="cart.items_count > 1">
                        <button
                          class="flex uppercase shadow bg-red-600 text-white text-sm pt-1 pb-1 mt-1 px-4 rounded"
                                            @click="deleteConfirm(item.item_id, item.name)" >
                          <v-icon
                            name="ri-delete-bin-line"
                            scale="1"
                            class="mr-1"
                          />Löschen
                        </button>
                      </div>


                       <div class="flex mx-2"  v-else>
                        <button
                          class="flex uppercase shadow bg-red-600 text-white text-sm pt-1 pb-1 mt-1 px-4 rounded"
                                            @click="resetCart()" >
                          <v-icon
                            name="ri-delete-bin-line"
                            scale="1"
                            class="mr-1"
                          />Löschen
                        </button>
                      </div>


                        </div>
                    </div>
                </div>
                      <hr class="border-gray-400 my-3 border-dashed">








            
            <!-- End Each Product -->

            
           </div>

                     <div class="mb-3 " v-if="waitcart">
                <div class="text-lg text-gray-600 font-bold uppercase text-center">         <v-icon
                      name="ri-refresh-line"
                      scale="1.5"
                      animation="spin"
                      class="mr-2"
                    />Warenkorb wird aktualisiert!</div>          
            </div>
            
  
           
            </div>


       <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
                   v-else
              >
   
                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets4.lottiefiles.com/packages/lf20_kz2ylhyq.json"
        class="max-h-64 mx-auto m-10"
      />    

<div class="text-gray-500 text-2xl font-bold text-center">bitte zuerst ein Produkt einscannen</div>
           
            </div>


                 
                         <!-- <div class="flex justify-evenly mt-4">
          
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >
                         
                           <v-icon name="ri-qr-scan-line" scale="1.2" class="mb-0 mx-1" /> 
                          Scannen
                            
                        </button>

    
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center  mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >

                        <v-icon name="bi-credit-card-2-back" scale="1.2" class="mb-0 mx-1" /> 
                          Bezahlen
                            
                        </button>

                        </div> -->
            
 
        </div>


    </div>


       <div class="bg-white text-gray-800 rounded-xl shadow-lg flex w-full" v-else>
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl  overflow-y-auto">
            <div class="mb-3 ">
                <h1 class="text-2xl font-bold uppercase text-center">Warenkorb wird aktualisiert!</h1>          
            </div>
            

        <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
              >
 
  
           
            </div>
                 
     
            
 
        </div>


    </div>




  <div class="bg-white text-gray-800 rounded-xl shadow-lg flex w-full" v-if="showpay">
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl  overflow-y-auto">
            <div class="mb-3 ">
                <h1 class="text-2xl font-bold uppercase text-center"> Bezahlen</h1>
        
        
        
                   <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-if ="cart.items.length >= 1" >{{cart.items_qty}} Artikel | CHF {{totalcart}}</div>
           
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-else >0 Artikel | CHF 0.00</div>
           
           
            </div>
            

        <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
              >
 
  
           
            </div>
                 
                         <!-- <div class="flex justify-evenly mt-4">
          
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >
                         
                           <v-icon name="ri-qr-scan-line" scale="1.2" class="mb-0 mx-1" /> 
                          Scannen
                            
                        </button>

    
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center  mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >

                        <v-icon name="bi-credit-card-2-back" scale="1.2" class="mb-0 mx-1" /> 
                          Bezahlen
                            
                        </button>

                        </div> -->
            
 
        </div>


    </div>

 
</div>



  <div class="min-w-screen min-h-screen flex items-start justify-center px-3 py-3" v-if="showpay && !showcart">

    
  
  <div class="bg-white text-gray-800 rounded-3xl shadow-lg flex w-full " v-if="cart">
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl  overflow-y-auto">
            <div class="mb-3 ">
                <h1 class="text-2xl font-bold uppercase text-center"> <v-icon name="la-money-bill-wave-solid" scale="1.3" class="mb-1"/>  Bezahlen</h1>
                    <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-if ="cart.items.length >= 1" >{{cart.items_qty}} Artikel | CHF {{totalcart}}</div>
           
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center" v-else >0 Artikel | CHF 0.00</div>
            </div>
            

        <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
              v-if="cart.items.length >= 1">
 
        <qrcode-vue
                        :value="cartQR"
                        :size ="qrsize"
                        level="H"
                        class="mx-auto mt-4"
                      />


<div class="flex w-full mt-5"> 

  <div class="flex w-2/5">

                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets9.lottiefiles.com/packages/lf20_yzoqyyqf.json"
        class="max-h-48"
      />    

      </div>


                        <div class="flex w-3/5 text-xl font-bold text-center ml-5 mt-5">Bitte scannen Sie den QR-Code an der Kasse und Sie könenn dort bezahlen. </div>

                        </div>
           
            </div>

            
       <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
                   v-else
              >
   
                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets4.lottiefiles.com/packages/lf20_kz2ylhyq.json"
        class="max-h-64 mx-auto m-10"
      />    

<div class="text-gray-500 text-2xl font-bold text-center">bitte zuerst ein Produkt einscannen</div>
           
            </div>
                 
                         <!-- <div class="flex justify-evenly mt-4">
          
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >
                         
                           <v-icon name="ri-qr-scan-line" scale="1.2" class="mb-0 mx-1" /> 
                          Scannen
                            
                        </button>

    
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center  mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >

                        <v-icon name="bi-credit-card-2-back" scale="1.2" class="mb-0 mx-1" /> 
                          Bezahlen
                            
                        </button>

                        </div> -->
            
 
        </div>


    </div>

<div class="bg-white text-gray-800 rounded-3xl shadow-lg flex w-full " v-else>
        <div class="bg-white h-full min-w-full px-5 pt-6 pb-5 rounded-2xl  overflow-y-auto">
            <div class="mb-3 ">
                <h1 class="text-2xl font-bold uppercase text-center"> <v-icon name="la-money-bill-wave-solid" scale="1.3" class="mb-1"/>  Bezahlen</h1>
                <div class="text-base mt-2 text-gray-500 uppercase font-bold bg-gray-200 rounded-xl px-1 py-2 text-center">0 Artikel | CHF 0.00</div>
            </div>
            
            
       <div
                class="overflow-y-auto mt-5"
                style="height: 27rem"
                ref="messagesContainer"
                 
              >
   
                 <v-lottie-player
        name="scooterAnim"
        loop
        path="https://assets4.lottiefiles.com/packages/lf20_kz2ylhyq.json"
        class="max-h-64 mx-auto m-10"
      />    

<div class="text-gray-500 text-2xl font-bold text-center">bitte zuerst ein Produkt einscannen</div>
           
            </div>
                 
                         <!-- <div class="flex justify-evenly mt-4">
          
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >
                         
                           <v-icon name="ri-qr-scan-line" scale="1.2" class="mb-0 mx-1" /> 
                          Scannen
                            
                        </button>

    
           <button
               
                          class="border-2 border-white text-xl shadow-xl uppercase text-white rounded-lg font-bold flex items-center  mx-2 px-4 py-2"
                          style="background-color: #9c27b0"
                        >

                        <v-icon name="bi-credit-card-2-back" scale="1.2" class="mb-0 mx-1" /> 
                          Bezahlen
                            
                        </button>

                        </div> -->
            
 
        </div>


    </div>

 
</div>

                  <Dialog
            header="Produktinfo"
            position="top"
            :closeOnEscape="true"
            :dismissableMask="true"
            :modal="true"
            :closable="true"
            v-model:visible="displayModal"
            :style="{ width: '90vw', margin: '10px' }"
          >
            <div
              class="flex justify-center items-center w-full"
              v-if="productinfo"
            >
              <div
                class="w-full bg-gray-100 border-2 border-gray-300 p-5 rounded-md shadow-lg"
              >

                      <div class="w-full">
                    <img
                      
                      class="rounded-md border-2 border-gray-300 mx-auto w-32 h-32"
                      :src="productinfo.pic2"
                    />
                  </div>

                <div class="flex">
          
                  <div class="w-full flex flex-col">
                    <div class="text-base font-bold mb-2 mt-4">
                      {{ productinfo.name }} ({{ calcWeight }}
                      {{ calcMengeifBlank }})
                    </div>
                    <div class="flex justify-between mr-10">
                      <div class="flex text-base font-semibold mb-2">
                        CHF {{ calcPrice }}
                      </div>
                      <div class="flex ml-3 mt-1 text-gray-500 text-sm">
                        CHF {{ calcPriceWeight }}/{{ calcMenge }}
                      </div>
                    </div>

                    <div class="text-gray-800 mt-2">
                      <span v-html="productinfo.desc"></span>
                    </div>
                    <div class="flex mt-5" v-if="productinfo.zutaten">
                      <v-icon name="bi-flower1" scale="1.2" />
                      <div class="font-bold">
                        <span class="text-gray-600 mx-2">Zutaten:</span
                        >{{ productinfo.zutaten }}
                      </div>
                    </div>
                    <div class="flex mt-5" v-if="productinfo.hersteller">
                      <v-icon name="fa-leaf" scale="1.2" />
                      <div class="font-bold">
                        <span class="text-gray-600 mx-2">Hersteller:</span
                        >{{ productinfo.hersteller }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center items-center w-full" v-else>
              <div
                class="w-full bg-gray-100 border-2 border-gray-300 p-5 rounded-md tracking-wide shadow-lg"
              >
                <div class="w-full flex flex-col ml-5">
                  <div class="text-xl font-bold mb-2">
                    <v-icon
                      name="ri-refresh-line"
                      scale="2"
                      animation="spin"
                      class="mr-2"
                    />
                    Produktinformation wird aktualisiert ....
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center mt-5">
              <button
                @click="closeProductInfo"
                class="flex uppercase shadow bg-green-600 text-white text-base m-2 py-3 px-7 rounded"
              >
                <v-icon name="bi-x-circle" scale="1.2" class="mr-1" />Schliessen
              </button>
            </div>
          </Dialog>

<!-- Begin Footer -->

                              <OverlayPanel
            ref="op2"
            :showCloseIcon="false"
            :dismissable="false"
            id="overlay_panel"
            :breakpoints="{ '100px': '45vw', '100px': '40vw' }"
            :style="{ width: '90%' }"
          >
            <div
              class="flex rounded-lg items-center justify-center content-center"
            >
              <div class="flex flex-col">
                <div class="flex text-xl mt-2 my-2">
                  Möchten Sie neu starten?
                </div>
                <div class="flex items-center justify-center">
                  <button
                  @click="gotohome"
                    class="flex uppercase shadow bg-green-600 text-white text-base m-2 py-3 px-7 rounded"
                  >
                    <v-icon
                      name="ri-delete-bin-line"
                      scale="1.2"
                      class="mr-1"
                    />Ja
                  </button>
                  <button
                   @click="openrestart"
                    class="flex uppercase shadow bg-red-600 text-white text-base m-2 py-3 px-7 rounded"
                  >
                    <v-icon name="bi-x-circle" scale="1.2" class="mr-1" />Nein
                  </button>
                </div>
              </div>

         
            </div>
          </OverlayPanel>

                                        <OverlayPanel
            ref="op"
            :showCloseIcon="false"
            :dismissable="false"
            id="overlay_panel"
            :breakpoints="{ '400px': '45vw', '400px': '40vw' }"
            :style="{ width: '90%' }"
          >
            <div
              class="flex rounded-lg items-center justify-center content-center"
            >
              <div class="flex flex-col" v-if="!showwait3">
                <div class="flex text-xl mt-2 my-2">
                  Möchten Sie das Produkt  {{ modalname }} löschen?
                </div>
                <div class="flex items-center justify-center">
                  <button
                    @click="deleteProduct(sid, modalid, modalname)"
                    class="flex uppercase shadow bg-green-600 text-white text-base m-2 py-3 px-7 rounded"
                  >
                    <v-icon
                      name="ri-delete-bin-line"
                      scale="1.2"
                      class="mr-1"
                    />Ja
                  </button>
                  <button
                    @click="closeDelete()"
                    class="flex uppercase shadow bg-red-600 text-white text-base m-2 py-3 px-7 rounded"
                  >
                    <v-icon name="bi-x-circle" scale="1.2" class="mr-1" />Nein
                  </button>
                </div>
              </div>

               <div class="flex flex-col" v-if="showwait3">

     <div class="flex text-xl mt-2 my-2">
                 <v-icon name="ri-refresh-line" animation="spin" scale="1.5" class="text-gray-500 mx-2" /> Bitte kurz warten ...
                </div>
                 </div>
            </div>
          </OverlayPanel>





          

    <div class="bg-white bottom-0 w-full border-t border-gray-200 absolute flex flex-row">

              

          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500" @click="openrestart">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                       <v-icon name="co-home" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Home</span>
                </div>
            </div>

                 
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500" @click="gotoscan">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                          <v-icon name="ri-qr-scan-line" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Scan</span>
                </div>
            </div>
                   

                        
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500" @click="gotocart">
        
                <div class="text-center">

                                    <span
            class="bg-red-600 h-6 w-6 absolute top-0 left-50 text-xs text-white py-1 px-2 rounded-full" v-if="cart">{{cart.items_qty}}</span>            


                                                <span
            class="bg-gray-400 h-6 w-6 absolute top-0 left-50 text-xs text-white py-1 px-2 rounded-full" v-else>0</span>       
                    <span class="block h-8 text-3xl leading-8">
                        <v-icon name="bi-cart-check" scale="1.5" class="mb-3" /> 
                    </span>


                    <span class="block text-xs leading-none ">Warenkorb</span>
                          
                </div>
            </div>

                        
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500"  @click="gotopay">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                           <v-icon name="bi-credit-card-2-back" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Bezahlen</span>
                </div>
            </div>



<!-- End Footer -->



</div>


 </div>


</template>

<script>

import Footer from "../components/Footer.vue";
import QrReader from "../components/QRreader.vue";
import QrcodeVue from "qrcode.vue";
import axios from "axios";


export default {

  components: { Footer, QrReader, QrcodeVue },

name: "Home",
 

  data() {
    return {
    showQRscanner: true,
    showait: false,
    showwait2: false,
    showwait3: false,
    shownotfound: false,
    displayModal4: false,
    displayReset: false,
    showcart: false,
    showpay: false,
    waitcart: false,
    qrsku: "",
    inputsku: "",
    product: "",
    productinfo: "",
    products: "",
    inputname: "",
    item: "",
    cart:"",  
    sid: "",
    itemfound: false,
    firstscan: true,
    selectedorderqty: 1,
    deleteresult: "",
    totalcart: 0,
    modalname: "",
    modalid: "",
    timeOut: "",
    idelstate: "",
    displayModal: false,
    totalnumber: "0.00",
    qrvalue:"12345",
    cancelreset: false,
    qrsize: 180
    };
  },

  

  methods: {


       playSound() {
      var sound =
        "https://res.cloudinary.com/dde9xhzio/video/upload/v1638113168/POS/144418__zerolagtime__store-scanner-beep.mp3";
      var audio = new Audio(sound);
      audio.play();
      console.log("sound played");
    },


    forwardtohome() {
      this.displayReset = true;
      setTimeout (()=> this.checkreset(), 5000)
 
  },

  checkreset () {


    if (this.displayReset) {
        console.log("will be forwarded");
          this.$router.push({
            name: "Home",
          });

    }

  },


     resetCart() {
      this.createCart();
      setTimeout(() => this.getCart(), 2000);
    },


    createCart() {
        this.waitcart = true;
      fetch(this.serverurl+"/api/creatCart/")
        .then((res) => res.json())
        .then((data) => (this.sid = data.sid))
        .then(() => (this.waitcart = false))
        .catch((err) => console.log(err.Message));
      console.log(this.$data);

      // setTimeout(() => (this.showOverlay = false), 8000)
    },

      getProductbyWeight(sku2, sid2, qty2) {
      this.getProduct(sku2, sid2, qty2);
      this.displayModal4 = false;
      this.totalnumber = "0.00";
    },

    closePricebyWeight() {
      this.displayModal4 = false;
    },



        addnumber(no) {     

               this.currentnumber = no;
    
           if (this.totalnumber == "0.00" ) {
       this.totalnumber  = ""+ this.currentnumber

      }


      else {   

      this.totalnumber += this.currentnumber;
      return this.totalnumber, this.currentnumber;
    }
    
        },

        deletenumber() {
      let totalnumber = this.totalnumber.toString();
      let length = totalnumber.length - 1;
      let newnumber = totalnumber.slice(0, length);
      this.totalnumber = newnumber;
      return this.totalnumber;
    },

    closeReset() {
      this.displayReset = false;
      clearTimeout(this.timeOut);
                 this.timeOut = setTimeout(() => {
         this.forwardtohome()
     
     }, 2700000) 

    },

getInfo(sku) {
      this.displayModal = true;
      this.productinfo = "";
      this.getProdutInfo(sku);
      // this.speak("Hier finden Sie alle Informationen zum Produkt " + name);
    },

    
    getProdutInfo(sku) {
      fetch(this.serverurl+"/api/getProductInfo/" + sku)
        .then((res) => res.json())
        .then((data) => (this.productinfo = data))
        .catch((err) => console.log(err.Message));
      console.log(this.$data);

      // setTimeout(() => (this.showOverlay = false), 8000)
    },

      closeProductInfo() {
      this.displayModal = false;
    },


    deleteConfirm(id, name) {

      this.$refs.op.toggle(event);
      this.modalname = name;
      this.modalid = id;
    },

    openrestart() {
 this.$refs.op2.toggle(event);

    },

       closeDelete() {
    this.$refs.op.toggle(event);
    },
  
    deleteProduct(sid, pid) {

      this.showwait3 = true;


      fetch(
        this.serverurl+"/api/deleteProduct/" +
          sid +
          "/" +
          pid
      )
        .then((data) => (this.deleteresult = data))
        .then(() => this.getCart())
        .then(() => this.updateTotal())
        .then(() => this.showwait3 = false)
        .then(() => this.$refs.op.toggle())
        .catch((err) => console.log(err.Message));
      console.log(this.$data);

      // setTimeout(() => (this.showOverlay = false), 8000)
    },


       updateTotal() {
      var sum = 0;

      if (this.cart.items) {
        console.log("Total updated");
        this.cart.items.forEach((item) => {
          if (item.qty >= 0) {
            sum += item.qty * item.price;
            return (this.totalcart = sum.toFixed(2));
          } else {
            return (this.totalcart = sum.toFixed(2));
          }
        });
      }


    },


    rescan() {
      this.showQRscanner = true;
      this.shownotfound = false;
      this.itemfound = false,
      this.item = "";


    },

    addqty() {

      this.selectedorderqty = this.selectedorderqty + 1


    }, 

        gotoscan() {

      this.showcart = false;
      this.showQRscanner = true;
      this.showpay = false
   


    },

    gotohome() {

 this.openrestart();

 this.$router.push({
        name: "Home"
      });

    },


    gotocart() {

       this.getCart();

      this.showcart = true;
       this.showQRscanner = false;
      this.showpay = false

       },


    gotopay() {

      this.showcart = false;
       this.showQRscanner = false;
      this.showpay = true


       },

       removeqty() {

         if (this.selectedorderqty <= 0) {


           this.selectedorderqty = 0
         }

         else {

        
      this.selectedorderqty = this.selectedorderqty - 1
}



    }, 
  
      getInfobySku(sku) {
          this.item ="",
          this.showait2 = true,
          this.itemfound = false,
          this.firstscan = false,
      fetch(this.serverurl+"/api/getProductInfo/" +sku)
        .then((res) => res.json())
        .then((data) => (this.item = data))
        .then(()=>    this.showait = false )
        .then(()=>    this.itemfound = true )
        .then(()=>    this.showQRscanner = true)
        .catch(() => this.itemfound = false );
      
      console.log(this.$data);

      setTimeout (()=>     
     { if (!this.item.message) {
 this.itemfound = true;
      }
      else 
      {
        this.itemfound = false;      
      }
     },1000
)

    },



       gotoproductpage(sku) {

        this.$router.push({
        name: "Product", 
        params: { sku: sku }
      });

    },

    Closeme() {

      this.showQRscanner = !this.showQRscanner
    },


    onDecoded(sku) {
      console.log("QR decoded");
      this.playSound();
      this.item = "";
      let sku2 = sku.slice(-4);
      sku2 = sku2.toLowerCase();


      if (sku2.includes("ve")) {
        this.getProdutInfo(sku2);
        this.displayModal4 = true;
       }

      this.getInfobySku(sku2);
      this.inputsku = sku2;
      this.showait = true;  
      this.showQRscanner = true;     

    },


        getCart() {
      this.waitcart = true;
      fetch(this.serverurl+"/api/getCart/"+this.sid)
        .then((res) => res.json())
        .then((data) => (this.cart = data))
        .then((data) => (this.waitcart = false))
                .then(()=> this.updateTotal())
        // .then(() => setTimeout(this.scrollToId(this.product.sku), 700))
        .catch((err) => console.log(err.Message));
      console.log(this.$data);

      // setTimeout(() => (this.showOverlay = false), 8000)
    },


     getProduct(sku, sid, qty) {

          this.showwait2 = true;
              
      axios({
        url:
          this.serverurl+"/api/getProduct/" +
          sku +
          "/" +
          sid +
          "/" +
          qty,
        method: "get",
      })
        .then((response) => {
          console.log("Product found...");
          this.product = response.data;
          this.shownotfound = false;
          this.showwait2 = false;
          this.firstscan = true;
          this.selectedorderqty = 1;
          this.item = "";
          // this.showOverlay = true;
          // this.showWait = false;
          // this.placeholdercolor = true;
          // this.showScanmore = true;
          // this.myplaceholder = "Bitte scannen ...";
          // this.text = response.data.product.name;
          // this.speak(
          //   "Das Produkt " +
          //     this.text +
          //     " wurde hinzugefügt. Bitte weitere Produkte scannen oder die Taste Bezahlen drücken."
          // );
          // this.focusInput();
          this.getCart();
             })
        .catch((err) => {
          // console.log("Error Fetching...");
          // this.showOverlay = true;
          // this.showWait = false;
          // this.placeholdercolor = true;
          // this.showScanmore = false;
          // this.showNotFound = true;
          // this.product = "";
          // this.myplaceholder = "Bitte nochmals scannen ...";
          // this.speak(
         
        //   "Das Produkt wurde nicht gefunden, bitte nochmals scannen ..."
          // );
          // this.focusInput();
          // this.getCart();
          // this.updateTotal();
        });
    },

    



  },

   computed: {


    
    updateCart() {
      console.log("Cart items enhanced");
      this.cart.items.forEach((item) => {
        item.qty2 = item.qty + "x";
        item.subtotal = item.qty * item.price;
        item.subtotal2 = item.subtotal.toFixed(2);
      });
    },

    cartQR() {
      let qr = this.sid + "####";

      return qr;
    },

    checkPricebyWeight() {
      let check1 = parseInt(this.totalnumber);
      let check2 = parseFloat(this.totalnumber);

      if (check1 == check2) {
        return (this.checkinteger = false);
      } else if (!this.totalnumber) {
        return (
          (this.checkinteger = true),
          (this.checktext = "Bitte Stückzahl eintippen")
        );
      } else {
        return (
          (this.checkinteger = true),
          (this.checktext = 'Bitte nur  ganze Zahl eingeen (ohne ".") ')
        );
      }
    },

    checkPricebyKG() {
      let check1 = parseFloat(this.totalnumber);

      if (check1 > 0.001 && check1 <= 50) {
        return (this.checkweight = false);
      } else if (check1 > 50) {
        return (
          (this.checkweight = true),
          (this.checkkgtext = "Bitte Gewicht kontrollieren")
        );
      } else if (check1 <= 0.001) {
        return (
          (this.checkweight = true),
          (this.checkkgtext = "Bitte Gewicht kontrollieren")
        );
      } else {
        return (this.checkkgtext = false);
      }
    },

    pricebyweight() {
      let sum = 0;

      if (parseFloat(this.totalnumber) > 0) {
        sum = parseFloat(this.totalnumber) * this.productinfo.price;
        sum = sum.toFixed(2);
        return sum;
      } else {
        return this.productinfo.price;
      }
    },

    calcWeight() {
      var weight2 = 0;

      if (this.productinfo.menge == "kg") {
        weight2 = this.productinfo.weight;
        return weight2;
      } else {
        return (weight2 = this.productinfo.weight * 1000);
      }
    },

    calcPrice() {
      var price2 = 0;

      if (!this.productinfo.price) {
        return price2.toFixed(2);
      } else {
        price2 = parseFloat(this.productinfo.price);
        return price2.toFixed(2);
      }
    },

    calcPriceWeight() {
      var weight3 = 0;

      if (this.productinfo.menge == "g" || this.productinfo.menge == "G") {
        weight3 =
          (this.productinfo.price / (this.productinfo.weight * 1000)) * 100;

        return weight3.toFixed(2);
      } else if (this.productinfo.sku.includes("VE")) {
        weight3 = this.productinfo.price;
        return weight3;
      } else if (
        this.productinfo.menge == "kg" ||
        this.productinfo.menge == "Kg" ||
        this.productinfo.menge == "KG"
      ) {
        weight3 = this.productinfo.price / this.productinfo.weight;

        return weight3.toFixed(2);
      } else if (
        this.productinfo.menge == "ml" ||
        this.productinfo.menge == "ML" ||
        this.productinfo.menge == "ML"
      ) {
        weight3 =
          (this.productinfo.price / (this.productinfo.weight * 1000)) * 100;

        return weight3.toFixed(2);
      } else if (!this.productinfo.menge) {
        weight3 = this.productinfo.price / this.productinfo.weight;
        return weight3.toFixed(2);
      } else if (
        this.productinfo.menge == "stk" ||
        this.productinfo.menge == "Stk" ||
        this.productinfo.menge == "Stk."
      ) {
        weight3 = this.productinfo.price / (this.productinfo.weight * 1000);
        return weight3.toFixed(2);
      } else {
        return (weight3 = "");
      }
    },

    calcMengeifBlank() {
      var menge1 = "";

      if (!this.productinfo.menge) {
        console.log("weight unknown");
        return (menge1 = "g");
      } else if (this.productinfo.sku.includes("VE")) {
        console.log("Vegi by weight");
        return (menge1 = "nach Gewicht ");
      } else {
        console.log("weight known");
        return (menge1 = this.productinfo.menge);
      }
    },

    calcMenge() {
      var menge2 = "";

      if (this.productinfo.menge == "g") {
        return (menge2 = "100g");
      } else if (this.productinfo.menge == "ml") {
        return (menge2 = "100ml");
      } else if (!this.productinfo.menge) {
        return (menge2 = "kg");
      } else if (this.productinfo.menge == "kg") {
        return (menge2 = "kg");
      } else if (
        this.productinfo.menge == "stk" ||
        this.productinfo.menge == "Stk" ||
        this.productinfo.menge == "Stk."
      ) {
        return (menge2 = "Stk");
      } else {
        return (menge2 = "g");
      }
    },
  },


  mounted() {
  
      this.createCart();

           this.timeOut = setTimeout(() => {
         this.forwardtohome()
     
     }, 2700000) 

  },

    unmounted() {

        clearTimeout(this.timeOut);

},


};
</script>

<style>


</style>
