<template>
  <div
    class="w-full mt-0 bg-red-300 bg-opacity-20 border-4 border-white rounded-tr-lg rounded-tl-lg"
  >
    <div class="flex">
      <div class="w-1/4">
        <router-link
          :to="{
            name: 'Start',
          }"
        >
          <div class="relative my-2" @click="playSound">
            <div
              class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
              :class="ShowStart ? 'bg-green-500' : 'bg-gray-500'"
            >
              <span class="text-center text-gray-100 w-full">
                <v-icon name="ri-home-smile-line" scale="1.5" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold uppercase mt-1"
              :class="ShowStart ? 'text-green-500' : 'text-gray-400'"
            >
              Start {{ idelstate2 }} 
            </div>
          </div>
        </router-link>
      </div>
      <div class="w-1/4">
        <div class="relative my-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <div
              class="w-full bg-gray-200 rounded items-center align-middle align-center"
            >
              <div
                class="w-0 py-1 rounded"
                style="width: 100%"
                :class="ShowScan ? 'bg-green-500' : 'bg-gray-400'"
              ></div>
            </div>
          </div>

          <div
            class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="ShowScan ? 'bg-green-500' : 'bg-gray-500'"
          >
            <span class="text-center text-gray-100 w-full">
              <v-icon name="bi-upc-scan" scale="1.2" />
            </span>
          </div>
          <div
            class="text-xs text-center font-bold uppercase mt-1"
            :class="ShowScan ? 'text-green-600' : 'text-gray-500'"
          >
            Scannen
          </div>
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative my-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <div
              class="w-full bg-gray-200 rounded items-center align-middle align-center"
            >
              <div
                class="w-0 py-1 rounded"
                style="width: 100%"
                :class="ShowPay ? 'bg-green-500' : 'bg-gray-400'"
              ></div>
            </div>
          </div>

          <div
            class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="ShowPay ? 'bg-green-500' : 'bg-gray-500'"
          >
            <span class="text-center text-gray-100 w-full">
              <v-icon name="px-wallet" scale="1.2" />
            </span>
          </div>
          <div
            class="text-xs text-center font-bold uppercase mt-1"
            :class="ShowPay ? 'text-green-600' : 'text-gray-500'"
          >
            Bezahlen
          </div>
        </div>
      </div>

      <div class="w-1/4">
        <div class="relative my-2">
          <div
            class="absolute flex align-center items-center align-middle content-center"
            style="
              width: calc(100% - 2.5rem - 1rem);
              top: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <div
              class="w-full bg-gray-200 rounded items-center align-middle align-center"
            >
              <div
                class="w-0 py-1 rounded"
                style="width: 100%"
                :class="ShowFinish ? 'bg-green-500' : 'bg-gray-400'"
              ></div>
            </div>
          </div>

          <div
            class="w-10 h-10 mx-auto border-2 border-gray-200 rounded-full text-lg text-white flex items-center"
            :class="ShowFinish ? 'bg-green-500' : 'bg-gray-500'"
          >
            <span class="text-center text-gray-100 w-full">
              <svg
                class="w-full fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  class="heroicon-ui"
                  d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
                /></svg
            ></span>
          </div>
          <div
            class="text-xs text-center font-bold uppercase mt-1"
            :class="ShowFinish ? 'text-green-600' : 'text-gray-500'"
          >
            Fertig
          </div>
        </div>
      </div>

      <div
        class="w-1/12 bg-purple-200"
        v-if="ShowFinish && ShowScan && ShowPay && ShowPayCash"
      >
        <router-link
          :to="{
            name: 'Start',
          }"
        >
          <div class="relative my-2">
            <div
              class="w-10 h-10 mx-auto bg-blue-500 rounded-full text-lg text-white flex items-center"
            >
              <span class="text-center text-white w-full">
                <v-icon name="ri-home-8-line" scale="1.1" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
            >
              Home
            </div>
          </div>
        </router-link>
      </div>

      <div
        class="w-1/12 bg-purple-200"
        v-else-if="ShowScan && !ShowPay && !ShowPayCash"
      >
        <router-link
          :to="{
            name: 'Start',
          }"
        >
          <div class="relative my-2" @click="playSound">
            <div
              class="w-10 h-10 mx-auto bg-purple-500 rounded-full text-lg text-white flex items-center"
            >
              <span class="text-center text-white w-full">
                <v-icon name="ri-restart-line" scale="1.1" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
            >
              Reset
            </div>
          </div>
        </router-link>
      </div>

      <div
        class="w-1/12 bg-blue-200"
        v-else-if="ShowStart && !ShowPay && !ShowPayCash"
      >
        <router-link
          :to="{
            name: 'Home',
          }"
        >
          <div class="relative my-2" @click="playSound">
            <div
              class="w-10 h-10 mx-auto bg-blue-500 rounded-full text-lg text-white flex items-center"
            >
              <span class="text-center text-white w-full">
                <v-icon name="ri-home-8-line" scale="1.1" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
            >
              Home
            </div>
          </div>
        </router-link>
      </div>

      <div class="w-1/12 bg-blue-200" v-else-if="ShowStart && ShowPayCash">
        <router-link
          :to="{
            name: 'Pay',
            params: { sid: sid },
          }"
        >
          <div class="relative my-2" @click="playSound">
            <div
              class="w-10 h-10 mx-auto bg-blue-500 rounded-full text-lg text-white flex items-center"
            >
              <span class="text-center text-white w-full">
                <v-icon name="fa-backward" scale="1.1" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
            >
              Zurück
            </div>
          </div>
        </router-link>
      </div>

      <div class="w-1/12 bg-blue-200" v-else>
        <router-link
          :to="{
            name: 'Search',
            params: { sid: sid },
          }"
        >
          <div class="relative my-2" @click="playSound">
            <div
              class="w-10 h-10 mx-auto bg-blue-500 rounded-full text-lg text-white flex items-center"
            >
              <span class="text-center text-white w-full">
                <v-icon name="fa-backward" scale="1.1" />
              </span>
            </div>
            <div
              class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
            >
              Zurück
            </div>
          </div>
        </router-link>
      </div>

      <div class="w-1/12 bg-pink-200 rounded-tr-lg">
        <div class="relative my-2" @click="openHelp">
          <div
            class="w-10 h-10 mx-auto bg-pink-500 rounded-full text-lg text-white flex items-center"
          >
            <span class="text-center text-white w-full">
              <v-icon name="fa-regular-question-circle" scale="1.5" />
            </span>
          </div>
          <div
            class="text-xs text-center font-bold text-gray-600 uppercase mt-1"
          >
            Hilfe
          </div>
        </div>
      </div>
    </div>

    <Dialog
      header="Info"
      position="top"
      :closeOnEscape="false"
      :dismissableMask="false"
      :modal="false"
      :closable="false"
      v-model:visible="displayReset"
      :style="{ width: '90vw', margin: '50px' }"
    >
      <div class="flex justify-center text-red-500">
        <v-icon name="ri-restart-fill" scale="15" animation="spin" />
      </div>
      <div
        class="flex text-center text-gray-600 text-xl font-bold justify-center m-5"
      >
        Die Kasse wird zurückgesetzt...
      </div>

      <div clas="flex">
        <div
          @click="closeReset"
          class="bg-red-500 text-2xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-6 text-center"
        >
          Abrechen
        </div>
      </div>
    </Dialog>

    <Dialog
      header="Hilfe"
      position="top"
      :closeOnEscape="true"
      :dismissableMask="true"
      :modal="true"
      :closable="true"
      v-model:visible="displayHelp"
      :style="{ width: '50vw', margin: '50px' }"
    >
      <div class="flex justify-center">
        <img
          src="https://res.cloudinary.com/dde9xhzio/image/upload/v1617877015/POS/helpicon.jpg"
          class="mx-auto h-64"
        />
      </div>
      <div
        class="flex text-center text-gray-600 text-xl font-bold justify-between m-5"
      >
        Weitere Informationen werden auf dem oberen Bildschirm angezeight. Falls
        Sie Fragen haben oder eine Beratung wünschen, bitte drücken Sie auf die
        Türklingel rechts, wir rufen Ihnen sofort zurück.
      </div>

      <div clas="flex">
        <div
          @click="closeHelp"
          class="text-2xl shadow-xl uppercase text-white rounded-lg font-bold py-4 px-6 text-center"
          style="background-color: #9c27b0"
        >
          OK
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { useIdle } from "@vueuse/core";

const { idle, lastActive } = useIdle(15 * 60 * 1000);

export default {
  props: ["step", "sid", "screenguide"],

  data() {
    return {
      clickonmenu: false,
      ShowStart: false,
      ShowScan: false,
      ShowPay: false,
      showPayCash: false,
      ShowFinish: false,
      screenstatus: "",
      cashready: "",
      poscontrol: "",
      printerstatus: "",
      displayHelp: false,
      displayReset: false,
      ResetConfirm: true,
      timeOut: "",
      idelstate: "",
      otgready: "",
    };
  },

  methods: {
    openHelp() {
      this.displayHelp = true;
    },

    closeHelp() {
      this.displayHelp = false;
    },

    closeReset() {
      this.displayReset = false;
    },

    getScreenguide() {
      if (this.ShopPos) {
        fetch(
          this.serverurl+"/api/screenguide/" +
            this.screenguide
        )
          .then((res) => res.json())
          .then((data) => (this.screenstatus = data))
          .catch((err) => console.log(err.Message));
        console.log(this.$data);
      }
    },

    resetPrinter() {
      if (this.ShopPos) {
        fetch(this.serverurl+"/api/printerready")
          .then((res) => res.json())
          .then((data) => (this.printerstatus = data))
          .catch((err) => console.log(err.Message));
        console.log(this.$data);
      }
    },

    getCashReady() {
      if (this.ShopPos) {
        fetch(this.serverurl+"/api/cashready")
          .then((res) => res.json())
          .then((data) => (this.cashready = data))
          .catch((err) => console.log(err.Message));
        console.log(this.$data);
      }
    },

    resetCashReady() {
      if (this.ShopPos) {
        fetch(this.serverurl+"/api/resetcashready")
          .then((res) => res.json())
          .then((data) => (this.cashready = data))
          .catch((err) => console.log(err.Message));
        console.log(this.$data);
      }
    },

    OTGReady() {
      if (this.ShopPos) {
        fetch(this.serverurl+"/api/otgready")
          .then((res) => res.json())
          .then((data) => (this.otgready = data))
          .catch((err) => console.log(err.Message));
        console.log(this.$data);
      }
    },

    checkStep() {
      if (this.step == "Start") {
        console.log("Step is Start");
        this.ShowStart = true;
        this.ShowScan = false;
        this.ShowPay = false;
        this.ShowPayCash = false;
        this.ShowFinish = false;
        this.resetCashReady();
      } else if (this.step == "Scan") {
        console.log("Step is Scan");
        this.ShowStart = true;
        this.ShowScan = true;
        this.ShowPay = false;
        this.ShowPayCash = false;
        this.ShowFinish = false;
        this.OTGReady();
        this.resetCashReady();
      } else if (this.step == "Pay") {
        console.log("Step is Pay");
        this.ShowStart = true;
        this.ShowScan = true;
        this.ShowPay = true;
        this.ShowPayCash = false;
        this.ShowFinish = false;
        this.resetCashReady();
      } else if (this.step == "PayCash") {
        console.log("Step is PayCash");
        this.ShowStart = true;
        this.ShowScan = true;
        this.ShowPay = true;
        this.ShowPayCash = true;
        this.ShowFinish = false;
        if (this.screenguide == "bar") {
          this.getCashReady();
        } else {
          this.resetCashReady();
        }
      } else if (this.step == "Finish") {
        console.log("Step is Finish");
        this.ShowStart = true;
        this.ShowScan = true;
        this.ShowPay = true;
        this.ShowPayCash = true;
        this.ShowFinish = true;
        this.resetCashReady();
        this.resetPrinter();
      } else {
        this.ShowStart = false;
        this.ShowScan = false;
        this.ShowPay = false;
        this.ShowPayCash = false;
        this.ShowFinish = false;
        console.log("No Step defined!");
        this.resetCashReady();
      }
    },
    playSound() {
      var sound =
        "https://res.cloudinary.com/dde9xhzio/video/upload/v1617796719/POS/sound.mp3";
      var audio = new Audio(sound);
      audio.play();
      console.log("sound played");
      window.navigator.vibrate([250, 250]);
    },

    forwardtocard() {
      if (idle.value) {
        console.log("will be forwarded");

        if (this.$route.name == "Start") {
          console.log("already home");
          this.displayReset = false;
          this.$router.push({
            name: "Home",
          });
        } else {
          console.log("forwarded to destination");
          this.displayReset = false;
          this.$router.push({
            name: "Home",
          });
        }
      } else {
        console.log("not forwarded");
      }
    },
  },
  computed: {
    idelstate2() {
      if (idle.value) {
        this.displayReset = true;
        console.log("system idle");
        setTimeout(() => this.forwardtocard(), 6000);

        // return (this.idelstate = idle.value);
      } else {
        // return (this.idelstate = idle.value);
        this.displayReset = false;
        console.log("system busy");
      }
    },
  },

  mounted() {
    this.checkStep();
    this.getScreenguide();
    this.displayReset = false;
  },
};
</script>

<style>
</style>
