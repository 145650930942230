<template>

  
    <div class="bg-white bottom-0 w-full border-t border-gray-200 absolute flex flex-row">

              

          <div class="flex w-full items-center justify-center ml-2 p-2 text-indigo-500 hover:text-indigo-500" @click="gotohome">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                       <v-icon name="co-home" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Home</span>
                </div>
            </div>

                 
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500" @click="gotoscan">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                          <v-icon name="ri-qr-scan-line" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Scan</span>
                </div>
            </div>
                   

                        
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500" @click="gotocart">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                        <v-icon name="bi-cart-check" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Warenkorb</span>
                </div>
            </div>

                        
          <div class="flex w-full items-center justify-center ml-2 p-2 text-gray-500 hover:text-indigo-500">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                           <v-icon name="bi-credit-card-2-back" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Bezahlen</span>
                </div>
            </div>



<!--        
                    <router-link
          :to="{
            name: 'Home',
          }"
        >  
            <div class="flex w-full items-center justify-center ml-2 p-2 text-indigo-500 hover:text-indigo-500">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                       <v-icon name="co-home" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none ">Home</span>
                </div>
            </div>
                    </router-link>




                                      <router-link
          :to="{
            name: 'Scanner',
          }"
        >  
            <div class="flex w-full text-gray-500 hover:text-indigo-500">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                       <v-icon name="ri-qr-scan-line" scale="1.5" class="mb-3" /> 
                    </span>
                          <span class="block text-xs leading-none ">Scan</span>
                </div>
            </div>
                                      </router-link>

                    <router-link
          :to="{
            name: 'Cart',
          }"
        > 
                <div class="flex w-full text-gray-500 hover:text-indigo-500">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                      <v-icon name="bi-cart-check" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none">Warenkorb</span>
                </div>
            </div>
                    </router-link>

                                      <router-link
          :to="{
            name: 'Scanner',
          }"
        >  


            <div class="flex w-full items-center justify-center p-2 text-gray-500 hover:text-indigo-500">
                <div class="text-center">
                    <span class="block h-8 text-3xl leading-8">
                       <v-icon name="bi-credit-card-2-back" scale="1.5" class="mb-3" /> 
                    </span>
                    <span class="block text-xs leading-none">Bezahlen</span>
                </div>
            </div>

                                      </router-link> -->
 
 
 
 </div>

  
</template>

<script>
export default {



methods: {

gotohome() {


this.$router.push({ name: 'Home', params: { } })


},

gotocart() {


this.$router.push({ name: 'Cart', params: { } })


},

gotoscan() {


this.$router.push({ name: 'Scanner', params: { } })


}

}


};
</script>

<style>
</style>
